import React, { FormEvent, useState } from "react";
import emailjs from '@emailjs/browser';
import axios from "axios";


const EmailRestAPI = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [content, setContent] = useState('');

    const handleSubmit = async(e: FormEvent<HTMLFormElement>) => {       
        e.preventDefault() 
        console.log(e)


        const data = {
            service_id: import.meta.env.VITE_SERVICE_ID,
            template_id: import.meta.env.VITE_TEMPLATE_ID,
            user_id: import.meta.env.VITE_PUBLIC_KEY,
            template_params: {
                from_name: name,
                from_email: email,
                phone_number: phoneNumber,
                to_name: 'Charcoal Craze',
                message: content
            }
        }  

        // Send the email using EmailJS
        try {
            const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
            console.log(res.data);
            setName('');
            setEmail('');
            setPhoneNumber('');
            setContent('');
        } catch (error) {
            console.error("Error sending the email", error)
        }              
    }

    return (
        <form        
        style={{
            margin: "5px"
        }}
        onSubmit={handleSubmit}
        >
            <input 
            style={{
                paddingLeft: '25px',
                display:'block',
                width: '650px',
                maxWidth: '80vw',
                height: '25px',                
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '5px'                
            }} 
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
        />            
            <input 
            style={{
                display:'block',
                paddingLeft: '25px',
                width: '650px',
                maxWidth: '80vw',
                height: '25px',
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '5px'                
            }} 
            placeholder="Phone Number"
            type="tel" 
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <input 
            style={{
                display:'block',
                paddingLeft: '25px',
                width: '650px',
                maxWidth: '80vw',
                height: '25px',
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '5px'                
            }} 
            placeholder="Email"
            type='email' 
            value={email}
            onChange={(e) => setEmail(e.target.value)} 
            />
            <textarea            
            style={{
                display:'block',
                paddingLeft: '25px',
                paddingTop: '15px',
                width: '650px',
                maxWidth: '80vw',
                height: '100px',
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '5px'                
            }} 
            placeholder="How can we help you..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            />
            <input 
            type="submit"
            value="Send"
            style={{
                display:'block',
                width: '90px',
                height: '35px',
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '5px'                
            }}  />
        </form>
    )
}

export default EmailRestAPI