import React from "react";
import { Box, Link, Typography, IconButton } from "@mui/material"
import Grilling from '../assets/meat-sizzle-desktop-lq.webm'
import GrillingPhone from '../assets/meat-sizzle-mobile-lq.webm'
import './landingPage.css'
import InstagramIcon from '../assets/instagram-logo.webp'
import EmailRestAPI from "../components/EmailRestAPI";
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import CCText from '../assets/cc-text.webp'
import CCTextMobile from '../assets/cc-text-mobile.webp'
import { keyframes } from "@mui/material";

// const grandOpening = keyframes `
//     0%   {text-shadow: #A67C00 -1px -1px 2px;}
    

    
//     50%  {text-shadow: #FFDC73 -1px -1px 5px;}
    
  
    
//     100% {text-shadow: #A67C00 -1px -1px 2px;}
// `


const grandOpening = `
    filter: drop-shadow( 0px 0px 4px rgba(255, 255, 255, .9));
`

const location = `
https://www.google.com/maps/place/34%C2%B009'39.2%22N+118%C2%B017'19.7%22W/@34.160907,-118.2888162,59m/data=!3m1!1e3!4m4!3m3!8m2!3d34.160892!4d-118.288793?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D
`

function LandingPage() {

    const [trigger, setTrigger] = React.useState(false)

    useScrollPosition(({ prevPos, currPos }) => {
        const isShow = currPos.y === 0
        if (!isShow) {
          setTrigger(true)
        } else {
          setTrigger(false)
        }
      }, [])

    return (        
        <Box>
            {/* Section 1: Landing */}            
            <Box sx={{ display: { xs: 'none', md: 'block'}}}>
                <Box 

                    sx={{
                        width:'100%', 
                        position: 'absolute',
                        marginTop: '250px', 
                        transition:'opacity .5s ease-in-out', 
                        opacity: trigger? 0:1, 
                        zIndex: 100,
                        justifyContent:'center', alignItems:'center', textAlign:'center'
                    }}
                >

                    <img src={CCText} 
                        style={{  
                            maxWidth:'50vw', 
                            zIndex: 1,
                            filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,1))', 
                        }} 
                    />
        
                    <Typography 
                        textAlign='center' 
                        variant="h2" 
                        sx={{ 
                            color: 'white', 
                            fontWeight:'bold', 
                            fontSize: 'clamp(30px, 2vw, 3vw)', 
                            zIndex: 1, 
                            filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,1))', 
                            mt: '50px'
                        }} 
                    >
                        Let the grill speak for itself
                    </Typography>
                </Box>
                <video src={Grilling} muted controls={false} autoPlay loop style={{ backgroundSize: 'cover', width: '100%', opacity: '80%'  }} /> 
            </Box>               
            <Box sx={{ display: { xs: 'block', md: 'none'}}}>               
                    <Box 
                        sx={{ 
                            position: 'absolute', 
                            marginTop: '250px', 
                            transition:'opacity .5s ease-in-out', 
                            opacity: trigger? 0:1, 
                            zIndex: 100,
                            justifyContent:'center', alignItems:'center', textAlign:'center'
                        }}
                    >

                        <img src={CCTextMobile} style={{  maxWidth:'80%',  zIndex: 1,  filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,1))' }} />

                        <Typography 
                            textAlign='center' 
                            variant="h2" 
                            sx={{ 
                                marginTop:'50px',
                                color: 'white', 
                                fontWeight:'bold', 
                                fontSize: 'clamp(16px, 6vw, 24px)', 
                                zIndex: 1, 
                                filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,1))', 
                            }} 
                        >
                            Let the grill speak for itself
                        </Typography>
                    </Box>
                <video src={GrillingPhone} muted controls={false} autoPlay loop style={{ backgroundSize: 'cover', width: '100%', opacity: '80%'  }} /> 
            </Box>    
            {/* Section 2: Location */}
            <Box 
                    sx={{ 
                        textAlign: 'center',  
                        mt: '65px'
                }}>                   
                    
            </Box>
            <Box>                   
                <Box sx={{ mx: 'auto'  }}>
                    <Typography sx={{ 
                        color: '#FFFFFF', 
                        fontSize: '45px', 
                        mt: 5, 
                    textAlign: 'center' }}>
                        Location:
                    </Typography>
                </Box>                
                <Box id="location" sx={{ 
                    maxWidth: { xs: '100%', sm: "600px"}, 
                height: { xs: '100%'}, 
                bgcolor: '#212021', 
                mx: 'auto', 
                borderRadius: '15px' }}>
                    <Typography sx={{ 
                        fontSize: { xs: '20px', sm: '24px', md: '30px'}, 
                    p: '2.5%', 
                    textAlign: 'center', 
                    color: '#FFFFFF'}}>
                        <Link 
                            href={location}
                            rel='norefferer noopener' target="_blank"
                            style={{ 
                                color : "white",
                                textDecoration: 'none'
                            }}
                        >
                            1300 Flower Street, Glendale, CA 91201
                        </Link>
                    </Typography>
                </Box>
                
                <Typography
                    sx={{
                        mt: '35px',
                        fontSize: '20px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                    }}
                >
                    Open Tuesdays & Thursdays from: 
                </Typography>
                <Typography
                sx={{
                    mt: '5px',
                    fontSize: '20px',
                    color: 'white',
                    textAlign: 'center',
                    mx: 'auto'
                    }}>
                    11 am to 2 pm.
                </Typography>                
            </Box>
            {/* <Box 
                sx={{ 
                    maxWidth: { xs: '100%', sm: "600px"}, 
                    height: { xs: '100%'}, 
                    bgcolor: '#212021', 
                    mx: 'auto', 
                    borderRadius: '15px',
                    mt: '35px',
                    pt: '15px',
                    pb: '15px'
                }}>
            <Typography
                sx={{
                   
                    fontSize: '16px',
                    color: 'white',
                    textAlign: 'center',
                    mx: 'auto'
                    }}>
                    You can text us your order using this format:
                </Typography>
                <Typography
                sx={{
                    mt: '10px',
                    fontSize: '14px',
                    color: 'white',
                    textAlign: 'center',
                    mx: 'auto'
                    }}>
                    - Name
                </Typography>
                <Typography
                sx={{
                    mt: '15px',
                    fontSize: '14px',
                    color: 'white',
                    textAlign: 'center',
                    mx: 'auto'
                    }}>
                    [Filet Mignon / Chicken Tenders / Pork Belly / Pork Tenderloin] [Plate / A La Cart] 
                </Typography>
                <Typography
                sx={{
                    mt: '10px',
                    fontSize: '14px',
                    color: 'white',
                    textAlign: 'center',
                    mx: 'auto'
                    }}>
                    - The time you would like to pick up your order
                </Typography>   
                <Box
                    sx={{ 
                        maxWidth: { xs: '80%', sm: "400px"}, 
                        height: { xs: '100%'}, 
                        bgcolor: '#403e40', 
                        mt: '15px',
                        mx: 'auto', 
                        borderRadius: '15px',                        
                        pt: '5px',
                        pb: '15px'}}
                    >                   
                    <Typography
                    sx={{
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto',
                        mt: '10px'
                        }}>
                        Single Order Example:
                    </Typography>
                    <Typography
                    sx={{
                        mt: '5px',
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                        }}>
                        - John D.
                    </Typography>             
                    <Typography
                    sx={{
                        mt: '5px',
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                        }}>
                        Filet Mignon Plate
                    </Typography>
                    <Typography
                    sx={{
                        mt: '5px',
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                        }}>
                        For 12:20pm
                    </Typography>                   
                </Box>   
                <Box
                    sx={{ 
                        maxWidth: { xs: '80%', sm: "400px"}, 
                        height: { xs: '100%'}, 
                        bgcolor: '#403e40', 
                        mt: '15px',
                        mx: 'auto', 
                        borderRadius: '15px',                        
                        pt: '5px',
                        pb: '15px'}}
                    >
                          <Typography
                    sx={{
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto',
                        mt: '15px'
                        }}>
                        Multiple Orders Example:
                    </Typography>
                    <Typography
                    sx={{
                        mt: '5px',
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                        }}>
                        - John D.
                    </Typography>             
                    <Typography
                    sx={{
                        mt: '5px',
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                        }}>
                        Filet Mignon Plate
                    </Typography>
                    <Typography
                    sx={{
                        mt: '5px',
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                        }}>
                        Pork Belly Plate
                    </Typography>
                    <Typography
                    sx={{
                        mt: '5px',
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                        }}>
                        Chicken A La Cart
                    </Typography>
                    <Typography
                    sx={{
                        mt: '10px',
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                        }}>
                        For 12:45pm
                    </Typography>                    
                </Box>       
                <Typography
                    sx={{
                        mt: '10px',
                        fontSize: '14px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                        }}>
                        We can take up to 10 orders for each pick up time.
                    </Typography>     
                <Typography sx={{
                    textAlign: 'center',
                    mx: 'auto',
                    mt: '15px'
                }}>                        
                    <a 
                        style={{                        
                            color: 'white', 
                            textDecoration: 'none'
                        }} 
                        href="tel:+18182939445"
                    >
                        Phone:+1 818 293 9445
                    </a>                               
                </Typography>
            </Box> */}

            <Box  
                mt={10 }
                sx={{ 
                    mx: 'auto', 
                    textAlign: 'center', 
                }}
            >
                <Typography fontSize={32} color='white' mb={4}>
                    Follow us on Instagram
                </Typography>

                <IconButton
                  size="large"
                //   onClick={()=>console.log('to instagram')}
                  href='https://www.instagram.com/charcoalcraze/'  rel='norefferer noopener' target="_blank"
                  sx={{
                    
                    transition: "all 0.3s ease-in-out",
                    opacity: 0.7,
                    height: '100px',
                    width: '100px',
                    ":hover": { opacity: 1 },
                  }}
                >
                  <img src={InstagramIcon} style={{height:'100px', width:'100px'}}/>
                </IconButton>
            </Box>
            {/* Section 3: Catering Calendar */}
            <Box>
                <Box id='bookEvent'>
                    <Typography 
                    sx={{ 
                        color: '#FFFFFF', 
                        textAlign: 'center', 
                        mt: '80px', 
                        fontSize: '32px' }}
                        >
                        Want delicious Armenian Fusion BBQ catered at your event?
                    </Typography>
                </Box>         
            </Box>
            {/* Section 4: Contact Form */}         
            <Box 
                sx={{ 
                    mx: 'auto', 
                    textAlign: 'center', 
                    mt: '20px'
                }}
            >
                <Typography 
                    sx={{ 
                        fontSize: '28px', 
                        color: 'white'
                    }}
                >
                    Contact Us
                </Typography>    
                <EmailRestAPI />
            </Box> 
            {/* Section 5: Footer */}
            <Box 
                sx={{ 
                    textAlign: 'center',
                    mt: '65px',
                    mb: '25px'
                }}
            >
                <Typography 
                    sx={{ 
                        color: 'white',
                        mx: 'auto'
                    }}
                >
                    Copyright © Charcoal Craze LLC 2024
                </Typography>
                <Typography sx={{ mx: '5px'}}>                        
                    <a 
                        style={{                        
                            color: 'white', 
                            textDecoration: 'none'
                        }} 
                        href="tel:+18182939445"
                    >
                        Phone:+1 818 293 9445
                    </a>                               
                </Typography>
            </Box>
        </Box>
    )
}

export default LandingPage