import Coke from '../assets/coke.webp'
import Sprite from '../assets/sprite.webp'
import Water from '../assets/water.webp'
import ComingSoon from '../assets/coming-soon.webp'

export type Item = {
    id: number,
    img: string,
    title: string,
    description: string,
    nutritionFacts: NutritionFacts,
}

export interface NutritionFacts {
  calories : number,
  totalFat : number,
  saturatedFat : number,
  transfat: number,
  polyUnsaturatedFat: number,
  monoUnsaturatedFat: number,
  cholesterol : number,  
  sodium: number,
  totalCarbs : number,
  dietaryFiber: number,
  sugar: number,
  protein: number,
  vitaminD: number,
  calcium: number,
  iron: number,
  potassium : number,
}

export const entreeData: Item[] = [
    {
      id: 1,
      img: ComingSoon,
      title: 'Beef Lule Kebab',      
      nutritionFacts:  {
        calories: 364,
        totalFat: 28,
        saturatedFat: 7.2,
        transfat: 0,
        polyUnsaturatedFat: 2,
        monoUnsaturatedFat: 15.8,
        cholesterol: 84,
        sodium: 214,
        totalCarbs: 4,
        dietaryFiber: 1,
        sugar: 1.5,
        protein: 26,
        vitaminD: 0.2,
        calcium: 40,
        iron: 2.2,
        potassium: 416.8,
      },
      description: ''
    },
    {
      id: 2,
      img: ComingSoon,
      title: 'Marinated Chicken Breast Skewer',
      nutritionFacts: {
        calories: 227,
        totalFat: 5,
        saturatedFat: 1.1,
        transfat: 0,
        polyUnsaturatedFat: 0.8,
        monoUnsaturatedFat: 1.3,
        cholesterol: 138,
        sodium: 85,
        totalCarbs: 0,
        dietaryFiber: 0,
        sugar: 0,
        protein: 43,
        vitaminD: 0,
        calcium: 9.5,
        iron: 0.7,
        potassium: 631.3
      },
      description: ''
    },
    {
      id: 3,
      img: ComingSoon,
      title: 'Pork Belly Skewer',
      nutritionFacts: {
        calories: 581,
        totalFat: 42.7,
        saturatedFat: 15.4,
        transfat: 0.4,
        polyUnsaturatedFat: 7,
        monoUnsaturatedFat: 18.2,
        cholesterol: 168,
        sodium: 189,
        totalCarbs: 0,
        dietaryFiber: 0,
        sugar: 0,
        protein: 45.5,
        vitaminD: 2.4,
        calcium: 91,
        iron: 1.8,
        potassium: 476
      },
      description: ''
    },
    {
      id: 4,
      img: ComingSoon,
      title: 'Pork Tenderloin Skewer',
      nutritionFacts: {
        calories: 581,
        totalFat: 42.7,
        saturatedFat: 15.4,
        transfat: 0.4,
        polyUnsaturatedFat: 7,
        monoUnsaturatedFat: 18.2,
        cholesterol: 168,
        sodium: 189,
        totalCarbs: 0,
        dietaryFiber: 0,
        sugar: 0,
        protein: 45.5,
        vitaminD: 2.4,
        calcium: 91,
        iron: 1.8,
        potassium: 476
      },
      description: ''
    }
    // {
    //   id: 4,
    //   img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    //   title: 'Beef Kebab',
    //   nutritionFacts: ['Calories: 220 (3oz).', 'Total Fat: 15g', 'Saturated Fat: 6g', 'Cholesterol: 82mg', 'Sodium: 46mg', 'Potassium: 280 mg', 'Carbohydrates: 0g', 'Protein: 22g'],
    //   misc: '',
    // },
    // {
    //   id: 5,
    //   img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    //   title: 'Mix & Match Skewer',
    //   nutritionFacts: ['Calories: 220 (3oz).', 'Total Fat: 15g', 'Saturated Fat: 6g', 'Cholesterol: 82mg', 'Sodium: 46mg', 'Potassium: 280 mg', 'Carbohydrates: 0g', 'Protein: 22g'],
    //   misc: 'Choice of meats.',
    // },
    // {
    //   id: 6,
    //   img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    //   title: 'The Works',
    //   misc: 'A bit of everything.',
    //   nutritionFacts: ['Calories: 220 (3oz).', 'Total Fat: 15g', 'Saturated Fat: 6g', 'Cholesterol: 82mg', 'Sodium: 46mg', 'Potassium: 280 mg', 'Carbohydrates: 0g', 'Protein: 22g'],
    // }
  ];
  
  export const sidesData: Item[] = [   
    {
        id: 1,
        img: ComingSoon,
        title: 'Tzatziki',
        nutritionFacts: {
          calories: 54,
          totalFat: 4.4,
          saturatedFat: 1.2,
          transfat: 0,
          polyUnsaturatedFat: 0.4,
          monoUnsaturatedFat: 2.6,
          cholesterol: 4.6,
          sodium: 110,
          totalCarbs: 2.6,
          dietaryFiber: 0.3,
          sugar: 2,
          protein: 1.5,
          vitaminD: 0,
          calcium: 64,
          iron: 0.2,
          potassium: 93.5
        },
        description: "",
    },
    {
        id: 2,
        img: ComingSoon,
        title: 'Cabbage Salad',
        nutritionFacts: {
          calories: 40,
          totalFat: 0,
          saturatedFat: 0,
          transfat: 0,
          polyUnsaturatedFat: 0,
          monoUnsaturatedFat: 0,
          cholesterol: 0,
          sodium: 420,
          totalCarbs: 6,
          dietaryFiber: 3,
          sugar: 3,
          protein: 1,
          vitaminD: 0,
          calcium: 40,
          iron: 1,
          potassium: 0
        },
        description: '',
    },
  ];
  
  export const drinksDessertsData: Item[] = [
      {
        id: 1,
        img: Coke,
        title: 'Coke',
        nutritionFacts: 
        {
          calories: 140,
          totalFat: 0,
          saturatedFat: 0,
          transfat: 0,
          polyUnsaturatedFat: 0,
          monoUnsaturatedFat: 0,
          cholesterol: 0,
          sodium: 45,
          totalCarbs: 39,
          dietaryFiber: 0,
          sugar: 39,
          protein: 0,
          vitaminD: 0,
          calcium: 0,
          iron: 0,
          potassium: 0
        },
        description: '',
      },
      {
        id: 2,
        img: Sprite,
        title: 'Sprite',
        nutritionFacts:        
        {
          calories: 140,
          totalFat: 0,
          saturatedFat: 0,
          transfat: 0,
          polyUnsaturatedFat: 0,
          monoUnsaturatedFat: 0,
          cholesterol: 0,
          sodium: 65,
          totalCarbs: 38,
          dietaryFiber: 0,
          sugar: 39,
          protein: 0,
          vitaminD: 0,
          calcium: 0,
          iron: 0,
          potassium: 0
        },
        description: '',
      },      
      {
        id: 3,
        img: Water,
        title: 'Water',
        nutritionFacts:        
        {
          calories: 0,
          totalFat: 0,
          saturatedFat: 0,
          transfat: 0,
          polyUnsaturatedFat: 0,
          monoUnsaturatedFat: 0,
          cholesterol: 0,
          sodium: 0,
          totalCarbs: 0,
          dietaryFiber: 0,
          sugar: 0,
          protein: 0,
          vitaminD: 0,
          calcium: 0,
          iron: 0,
          potassium: 0
        },
        description: '',
      },        
      // {
      //   id: 5,
      //   img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      //   title: 'Cake',
      //   nutritionFacts:        
      //   {
      //     calories: 0,
      //     totalFat: 0,
      //     saturatedFat: 0,
      //     cholesterol: 0,
      //     sodium: 0,
      //     potassium: 0,
      //     carbs: 0,
      //     protein: 0
      //   },
      //   misc: 'Choice of meats.',
      // },
      // {
      //   id: 6,
      //   img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      //   title: 'Magic',
      //   nutritionFacts:        
      //   {
      //     calories: 0,
      //     totalFat: 0,
      //     saturatedFat: 0,
      //     cholesterol: 0,
      //     sodium: 0,
      //     potassium: 0,
      //     carbs: 0,
      //     protein: 0
      //   },
      //   misc: 'Choice of meats.',
      // }
    ];
  
    export const nutritionalData = {
      entreeData: entreeData,
      sidesData: sidesData,
      drinksDessertsData: drinksDessertsData,
    }