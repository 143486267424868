import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { nutritionalData, Item} from '../../content/menu-items';
import { Box, Typography } from '@mui/material';

function NutritionTable({nutriFacts}:{nutriFacts: Item}) {
 
  return (
    <TableContainer
      sx={{ 
        width:'100%',
        
      }}  
      >    
      <Table 
        sx={{ 
          width:'100%',
          height: '800px',
        }} 
        aria-label="simple table"
      >         
        <TableHead        
          key={nutriFacts.id}>            
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
            size='medium'
            sx={{
              fontSize: {xs:'24px', md:'32px'},
              textAlign: 'center',
              p: '25px', m: 'auto'
            }}>
            {nutriFacts.title}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'20px', md:'26px'},
              display: "block"
            }}>           
                <b>Calories:</b> {nutriFacts.nutritionFacts.calories}
            </TableCell>    
          </TableRow>
          <TableRow>      
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
          >
              <b>Total Fat:</b> {nutriFacts.nutritionFacts.calories}g
            </TableCell>
          </TableRow>
            <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
          >
              <b>Saturated Fat:</b> {nutriFacts.nutritionFacts.saturatedFat}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
          >
              <b>Trans Fat:</b> {nutriFacts.nutritionFacts.transfat}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
            >
              <b>Poly Unsaturated Fat:</b> {nutriFacts.nutritionFacts.polyUnsaturatedFat}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
          >
              <b>Mono Unsaturated Fat:</b> {nutriFacts.nutritionFacts.monoUnsaturatedFat}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
          >
              <b>Cholesterol:</b> {nutriFacts.nutritionFacts.cholesterol}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
          >
              <b>Sodium:</b> {nutriFacts.nutritionFacts.sodium}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
          >
              <b>Total Carbohydrates:</b> {nutriFacts.nutritionFacts.totalCarbs}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
          >
              <b>Dietary Fiber:</b> {nutriFacts.nutritionFacts.dietaryFiber}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
          >
              <b>Sugar:</b> {nutriFacts.nutritionFacts.sugar}g
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
              fontSize: {xs:'14px', md:'18px'}
            }}
          >
              <b>Protien:</b> {nutriFacts.nutritionFacts.protein}g
            </TableCell>              
          </TableRow>
          <TableRow>
            <TableCell 
            size='small'
            sx={{
                fontSize: {xs:'14px', md:'18px'}
              }}>
                <b>Vitamin D:</b> {nutriFacts.nutritionFacts.vitaminD}mcg
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell 
              size='small'
              sx={{
                fontSize: {xs:'14px', md:'18px'}
              }}>
                <b>Calcium:</b> {nutriFacts.nutritionFacts.calcium}mg
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell 
              size='small'
              sx={{
                fontSize: {xs:'14px', md:'18px'}
              }}>
                <b>Iron:</b> {nutriFacts.nutritionFacts.iron}mg
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell 
              size='small'
              sx={{
                fontSize: {xs:'14px', md:'18px'}
              }}>
                <b>Potassium:</b> {nutriFacts.nutritionFacts.potassium}mg
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
    </TableContainer>
  );
}

export default NutritionTable;