import { Box, Dialog, Typography } from '@mui/material'
import { Demodal, useModal } from 'demodal'
import { muiDialog } from 'demodal/material-ui'
import { Item } from '../../content/menu-items'
import { BorderAllRounded, BorderColor } from '@mui/icons-material';
import NutritionTable from '../nutrition-table/NutritionTable';

const style = {
    
    color: 'white',
    
    textAlign: 'left',
    borderRadius: '10px',
    boxShadow: 24,
    p: 6,
  };

// Register your Confirm modal wrapping it with `Demodal.create`
const NutritionModal = Demodal.create(
  ({ nutritionFacts }:{ nutritionFacts: Item } ) => {
    // useModal hook to control UI components
    const nutritionModal = useModal()

    const handleModalClose = async () => {
        await nutritionModal.close()
    }
    // "title" and "message" are props sent with "modal.open()"
    return (
      <Dialog       
        sx={{  
            '.MuiDialog-paper':{
                bgcolor:'rgb(150,150,150)',
            }

        }}    
        {...muiDialog(nutritionModal)} 
        onClose={()=>{handleModalClose()}}
      >
      
          <NutritionTable nutriFacts={nutritionFacts}/>
     
      </Dialog>
    )
  }
)

export default NutritionModal