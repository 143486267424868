import { Counter } from "./features/counter/Counter"
import LandingPage from './pages/LandingPage'
import MenuPage from './pages/MenuPage'
import {ResponsiveAppBar} from './components/Navbar'
import "./App.css"
import { Box } from "@mui/material"
import { Route, Routes } from "react-router-dom"
import { Demodal } from "demodal"

function App() {
  return (
    <Box>
      <ResponsiveAppBar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/Menu" element={<MenuPage />} />
        </Routes>
        <Demodal.Container />
    </Box>
  )
}

export default App
