import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/material';
import { entreeData, sidesData, drinksDessertsData } from '../content/menu-items'
import NutritionModal from '../components/modals/NutritionModal';
import { Demodal } from 'demodal';
import ComingSoon from '../assets/coming-soon.webp'

export default function MenuPage() {

  return (
    <Box sx={{paddingTop:'200px'}}>
        {/* Image List: Entree's */}
        <ListSubheader component="div" sx={{ color: 'white', backgroundColor: 'transparent', textAlign: 'center', fontSize: '32px'}}>
          Entrees
        </ListSubheader>
        <Box 
          sx={{ 
            mx: 'auto', height: '100%', p: '15px', maxWidth:'1400px', display:'grid',
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(4, 1fr)"
            }
          }} 
        >
    
          {entreeData.map((item) => (
              <ImageListItem 
                sx={{
                  cursor: 'pointer', margin:'10px', filter: 'brightness(.7)' , ":hover":{filter:'brightness(1)'}
                }}
                key={`${item.id}-${item.title}`} 
                onClick={()=>Demodal.open(NutritionModal, { nutritionFacts : item})}   
              >
              <img
                  style={{ borderRadius: '10px'}}
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
              />
              <ImageListItemBar
                  sx={{ borderRadius: '10px' }}
                  title={item.title}
                  key={item.img}
                  subtitle={item.description}
                
              />
              </ImageListItem>
          ))}
        </Box>

        {/* Image List: Sides */}

        <ListSubheader component="div" sx={{ color: 'white', backgroundColor: 'transparent', textAlign: 'center', fontSize: '32px', marginTop:'50px'}}>
          Sides
        </ListSubheader>

        <Box 
          sx={{ 
            mx: 'auto', height: '100%', p: '15px', maxWidth:'1000px', display:'grid',
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)"
            }
          }} 
        >

          {sidesData.map((item) => (
              <ImageListItem 
              sx={{
                cursor: 'pointer', margin: '10px', filter: 'brightness(.7)' , ":hover":{filter:'brightness(1)'}
              }}
              key={`${item.id}-${item.title}`} 
              onClick={()=>Demodal.open(NutritionModal, { nutritionFacts : item})}>
              <img
                style={{ borderRadius: '10px' }}
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
              />
              <ImageListItemBar
                  title={item.title}
                  key={item.img}
                  subtitle={item.description}                
              />
              </ImageListItem>
          ))}
        </Box>

        <ListSubheader component="div" sx={{ color: 'white', backgroundColor: 'transparent', textAlign: 'center', fontSize: '32px', marginTop:'50px'}}>
          Drinks & Desserts
        </ListSubheader>
        {/* Image List: Drinks and Desserts */}
        <Box 
          sx={{ 
            mx: 'auto', height: '100%', p: '15px', maxWidth:'1000px', display:'grid',
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
              xl: "repeat(2, 1fr)"
            }
          }} 
        >
          {drinksDessertsData.map((item) => (
              <ImageListItem 
              sx={{
                cursor: 'pointer', margin: '10px' , filter: 'brightness(.7)' , ":hover":{filter:'brightness(1)'}
              }}
              key={`${item.id}-${item.title}`} 
              onClick={()=>Demodal.open(NutritionModal, { nutritionFacts : item})}>
              <img
                style={{ borderRadius: '10px'}}
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
              />
              <ImageListItemBar
                  title={item.title}
                  key={item.img}
                  subtitle={item.description}                
              />
              </ImageListItem>
          ))}
        </Box>
    </Box>
  );
}
